(function ($) {
  Drupal.behaviors.editorialCommon = {
    attach: function (context, settings) {
      // Load wink logo
      var winkLogo = location.protocol + '//' + location.hostname + '/media/export/cms/editorial_hub/wink.png',
          imgObj   = new Image();
      imgObj.src   = winkLogo;

      var articlecolor = $('.eh-article-formatter').attr('data-article-color'),
          carouselObj = $('.eh-carousel'),
          screenWidth = $(window).width(),
          clickEventType = 'click',
          hasTouch = 'ontouchstart' in window,
          quoteWrapper = $("[class*='eh-quote']", ".eh-article-formatter-v1"),
          flipOnHoverFormatter = $('.eh-flip-on-hover'),
          metaViewport = $('meta[name="viewport"]');

      // reset the zoom level
      if(generic.isiPad()) {
        metaViewport.attr('content', metaViewport.attr('content')+'; initial-scale=1;');
      }

      carouselObj.each(function(){
	var thisCarousel = $(this);
	if (thisCarousel.hasClass('eh_article_color')) {
	  thisCarousel.parents('.basic-elc-nodeblock-noderef').css('background',articlecolor);
	}
      });
      // Enclose open and close quotes symbol
      $('.marker.open-quote').html('\u201c');
      $('.marker.close-quote').html('\u201d');

      // Flip-On-Hover
      if(hasTouch) {
        // Only handle click for touch devices
        flipOnHoverFormatter.on('click', '.flip_front', function() {
          flipOnHoverFormatter.find('.flip').removeClass('fliped');
          $(this).parent().find('.flip').addClass('fliped');
        });
      }
      else {
        // Only handle mouse over/out events for non-touch devices
        flipOnHoverFormatter
          .on('mouseover', function() {
            $(this).find('.flip').addClass('fliped');
          })
          .on('mouseout', function() {
            $(this).find('.flip').removeClass('fliped');
          });
      }

      // When text container follows quote then reduce the margin to avoid large gap
      quoteWrapper.next().filter('[class*="basic-textarea"]').children().first().addClass('reset-margin-top');

      $(".eh-carousel").parents(".basic-elc-nodeblock-noderef").addClass('topCarousel');
      //Show Global footer when Social share icons displayed
      if(screenWidth <= 767) {
	$(".eh-social-share").parents('.body_container').addClass('show-global-footer');
      }
      //Article color update
      $('.eh_article_color').css('background',articlecolor);

      //Quick buy link
      // Analytics click function interrupts quick buy click, hence implemented delegate method
      $('body,article').on(clickEventType, '.btn-quickbuy, .clinique-product .img-plus', function(e) {
        e.preventDefault();
        var btnElement = $(this);
        var prodID = btnElement.attr('data-productid');
        if (typeof(skuColor) == 'undefined') {
          if (btnElement.hasClass('plus')) {
            skuColor = btnElement.css('background-color');
          }
          else {
            skuColor = btnElement.siblings('div').css('background-color');
          }
        }
        args = {};
        args.productIds = [prodID]
        var r;
        args.callback = function(data,skuColor) {
          r = data;
          var prodImg = $(e.target).parents('.product').find('.product-image').find('a');
          if($(e.target).hasClass('img-plus')) {
            prodImg = $(e.target).parent().find('div');
          }
          else if ($(e.target).hasClass('plus')) {
            prodImg = $(e.target);     
          }      
          var skuColor = prodImg[0] ? prodImg[0].style.backgroundColor : '#fff';
          args.productData = r.products[0];
          product = args.productData;
          var baseId = product ? product.PRODUCT_ID.match(/PROD(\d*)/) : '';
          var prodBaseID = args.productData.PRODUCT_ID.replace("PROD",'');

          var $qs = site.quickshop.render({
            productData: args.productData
          });
          var col0 = $qs.find('.close');
          $qs.css('display', 'block').find('.close').hide();
          var prodImageContainer = document.createElement('div');
          $(prodImageContainer).attr("class","col0");
          var linkElement = document.createElement('a');
          $(linkElement).attr("href","#").css('background-color',skuColor).attr("class","prod-img");

          var prodImage = document.createElement('img');
          if(product.FAMILY_CODE) {
            $(prodImage).attr("src","/media/export/cms/products/302x349/clq_" + product.FAMILY_CODE + "_302x349.png");
          }
          $qs.find('.prod-desc').html(product.DESCRIPTION);
          $qs.find('.price').text().replace('US','');
          $(linkElement).append($(prodImage));
          $(prodImageContainer).append($(linkElement)).append('<div class="spp-share-container"><div id="BVRRSummaryContainer"></div></div>');

          var prodURL = args.productData.DRUPAL_URL;

          col0.after($(prodImageContainer));
          var qsWrapperLi = document.createElement('div');
          $(qsWrapperLi).attr("id", "quickshop-wrapper").attr("class", "el-quickshop-wrapper").append($qs);
          var viewDetails =  "<a class='view-details btn-add-to-bag' href='"+ prodURL +"'>View Details</a>";
          $(qsWrapperLi).find('.add-to-bag').after(viewDetails);

          // Set the overlay width dynamically
          var windowWidth = $(window).width();

          generic.overlay.launch({
            content: qsWrapperLi,
            includeBackground: true,
            hideOnClickOutside: true,
            includeCloseLink: true,
            cssStyle: {
              width: windowWidth,
              height: '555px'
            }
          });

          if (typeof($BV) !== 'undefined') {
            $BV.ui(
              "rr",
              "show_reviews", {
                productId: prodBaseID,
                doShowContent: function() {
                  var icons = $qs.find('.icons');
                  if (icons.length != 0) {
                    icons.prependTo($('.BVRRSocialBookmarkingLinks'));
                  }
                }
              }
            );

            function formatReviewContainer() {
              if($('body').hasClass('device-mobile')) {
                $('.BVRRRatingNormalImage').insertAfter($('.col1 .price'));
                $qs.find('.icons').prependTo($qs.find('.BVRRSocialBookmarkingLinks'));
              }
              else {
                $('.BVRRRatingNormalImage').insertAfter($('.prod-hd'));
              }
              var noRatingImg = $('#BVRRRatingSummaryNoReviewsWriteImageLinkID img');
              noRatingImg.addClass('no_rating_img');
              ($('<div class="noRatingImgCont"/>').append(noRatingImg)).insertAfter($('.prod-hd'));
              $('.BVRRRatingSummaryLinks').insertAfter($('.BVRRSocialBookmarkingLinks'));
              $('.BVRROverallRatingContainer').insertAfter($('#BVRRRatingSummaryLinkReadID a'));
              $('#BVRRRatingSummaryLinkReadID a').attr('href',prodURL);
              $('#BVRRRatingSummaryLinkWriteID a').attr('href',prodURL);
              $('#BVRRRatingSummaryLinkWriteID a').removeAttr("onclick");
            }
            if ($('#BVRRSummaryContainer').is(':empty')) {
              $('#BVRRSummaryContainer').on('DOMSubtreeModified propertychange', function() {
                $('#BVRRSummaryContainer').off('DOMSubtreeModified propertychange');
                formatReviewContainer();
              });
            }
            else {
              formatReviewContainer();
            }
          }
          // Quick view overlay template for mobile
          if($('body').hasClass('device-mobile')) {
            ($qs.find('.spp-share-container')).insertAfter($('.spp_product_status'));
            ($qs.find('.col1 .sizes')).insertAfter($qs.find('.add-to-bag.btn-add-to-bag'));
            $qs.find('.col1 .sizes').append('<div class="imgIcon"></div>');
            var quickShopUl = $('.quickshop-sizes.quickshop-options');
            var quickShopSelect = $('<select/>').attr({'class': quickShopUl.attr('class')});
            quickShopUl.find('li').each(function() {
              var eachAnchor = $(this).find('> a');
              var eachOption = $('<option/>').attr({
                'class': eachAnchor.attr('class'),
                'data-skubaseid': eachAnchor.attr('data-skubaseid'),
                'value': eachAnchor.attr('data-skubaseid')
              }).text(eachAnchor.text());
              quickShopSelect.append(eachOption);
            });
            quickShopSelect.insertAfter(quickShopUl);
            ($qs.find('.col1 .price')).insertAfter($qs.find('.prod-hd'));
            $('#foreground-node').css({'height': $(window).height()+'px'});
            $qs.find('.icons').prependTo($qs.find('.BVRRSocialBookmarkingLinks'));
            quickShopSelect.change(function() {
               $(this).closest("div").prev(".btn-add-to-bag").attr('data-skubaseid', $( this ).val());
            });
            $('.quickshop-sizes option:empty').remove();
          }
        };
        args.productFields = editorialHub.mppProductFields;
        generic.productData.getProductsData(args);
      });
      
      //Article Overlay
      $('.btn-article').on(clickEventType, function(e) {
        var articleContent = $(this).next('.article-prev');
        var overlayContent = '<div class="article_overlay"> ' + articleContent.html() + '</div>';
        generic.overlay.launch({
          content: overlayContent,
          cssStyle: {
            padding: '0px',
            height: '460px',
            width: '810px',
            overflow: 'hidden',
            border: 'none'
          },
          includeBackground: true
        });
        shareableImgElement = $('#foreground-node .article-prev-content').find('.shareable');
        if(shareableImgElement.length != 0) {
          if(shareableImgElement.next('.eh-image-share').length != 0) {
            shareableImgElement.next('.eh-image-share').remove();
          }
            shareLinkImplementatioon(shareableImgElement);
          }
          shareEquelImageHeight();
        });
        $(window).on('eh_img_share_alignment', function() {
          shareEquelImageHeight();
        });
        $(window).on('load', function() {
          if($('.eh-landingpage-formatter').length == 0) { 
            $('.footer-top .back-to-top').addClass('hide');
          } 
        $('.shareable').each(function(e) {
          shareLinkImplementatioon($(this));
        });
        shareEquelImageHeight();
        $('.shareable').parents('.quote-container').addClass('highLightBg');
      });
      var commonResizeTimer = null;
      $(window).resize(function() {
        if(commonResizeTimer) {
          clearTimeout(commonResizeTimer);
        }
        commonResizeTimer = setTimeout(function() {
          shareEquelImageHeight();
          // carousel resizes on window focus
          $(window).focus();
          if($('body').hasClass('device-mobile')) {
            $('#foreground-node').css({'height': $(window).height()+'px'});
          }
        }, 250);
      });

      function shareLinkImplementatioon(imgElement) {
        var shareElement = $('.eh-image-share').clone().first();
        var imgElement = imgElement;
        if(imgElement.next('.eh-image-share').length == 0) {
          shareElement.insertAfter(imgElement);
          var shareIcons = imgElement.next().find('.share');
          var shareLink = imgElement.next().find('.share_link');
          var articleType = 'text';  
          var articleFormatter = $('.eh-article-formatter');
          if (articleFormatter.length !== 0) {
	    articleType = articleFormatter.attr('data-article-type');
          }
          var shareLink = imgElement.next().find('.share_link.'+articleType);
          shareLink.parent().show();
          var shareClose = imgElement.next().find('.share_close');
          shareClose.addClass(articleType).attr('data-article-type', articleType);
          var socialShareLinks = imgElement.next().find('.share_social_links');
          var linkHeight = (imgElement.height() - socialShareLinks.height()) / 2;
          shareElement.css({width:imgElement.width(), height: imgElement.height()});
          shareIcons.css({width:imgElement.width(), height: imgElement.height()});
          socialShareLinks.css({'margin-top': linkHeight});

          shareLink.on(clickEventType,function(e) {
	    // shareIcons.show();
	    shareIcons = $(this).parents('.eh-image-share').find('.share');
	    shareIcons.show();
	    shareIcons.css({'visibility':'visible'});
	    var linkHeight = (shareIcons.height() - socialShareLinks.height()) / 2;
	    e.preventDefault();
	    socialShareLinks.css({'margin-top': linkHeight});
            $(this).hide();
            if($(this).parents('.article_overlay').length > 0) {
              shareEquelImageHeight();
              $(this).parents('.eh-image-share').removeClass('shrink').css('left', 0);
            }
          });
          shareClose.on(clickEventType,function(e) {
            e.preventDefault();
            shareIcons.hide();
            shareIcons.css({'visibility':'hidden'});
            $(this).closest('.eh-image-share').find('.share_link.'+$(this).attr('data-article-type')).show();
            if($(this).closest('.article_overlay').length > 0) {
              $(this).closest('.eh-image-share').css('left', $(this).closest('.eh-image-share').width() - 35+'px').addClass('shrink');
            }
          });
          var img = $(this).attr('src');
          var url = document.documentURI;
          var shareText = ''
          var twitter_url = 'http://twitter.com/intent/tweet?url=' + encodeURI(url) + '&amp;text=' + encodeURI(shareText);
          var facebook_url = 'http://www.facebook.com/sharer.php?u=' + encodeURI(url) + '&amp;t=' + encodeURI(shareText);
          var pinterest_url = 'http://pinterest.com/pin/create/button/?url=' + encodeURI(url) + '&amp;media=' + encodeURI(window.location.protocol) + '//' + encodeURI(window.location.host) + img + '&amp;description=';
          imgElement.next().find('a.facebook').attr('href', facebook_url);
          imgElement.next().find('a.twitter').attr('href', twitter_url);
          imgElement.next().find('a.pinterest').attr('href', pinterest_url);
          /* Social share links */
          imgElement.next().find('.share_social_links .js-social_link').on(clickEventType, function(e) {
            if (!e) {
              var e = window.event;
              e.cancelBubble = true;
            }
            if (e.stopPropagation) {
              e.stopPropagation();
            }
	    var width = 600;
	    var height = 350;
	    var openUrl = $(this).attr('href');
	    // Allow for borders.
	    var leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
	    // Allow for title and status bars.
	    var topPosition = (window.screen.height / 2) - ((height / 2) + 50);
	    var windowFeatures = "status=no,height=" + height + ",width=" + width;
	    windowFeatures += ",resizable=yes,left=" + leftPosition + ",top=" + topPosition;
	    /*
	    // Specifically for the Quickshop on MPP, find and use the media URL for Pinterest sharing
	    if($(this).hasClass('js-share--pinterest') && $(this).parents('.js-quickshop-container').length) {
	      openUrl += '&media=' + turnRelativeToAbsolute($(this).parents('.js-quickshop-container').find('.js-gallery-img-large').attr('src'));
	    }
	    */
            if($(this).hasClass('mail')) {
              window.location.href = openUrl;
            }
            else {
              window.open(openUrl, 'sharer', windowFeatures);
            }
            e.preventDefault();
          });
        }
      }
      function shareEquelImageHeight() {
        $('.shareable').each(function(e) {
          var imgElement = $(this);
          var shareElement =  imgElement.next('.eh-image-share');
          var shareIcons = imgElement.next('.eh-image-share').find('.share');
          var socialShareLinks = imgElement.next().find('.share_social_links');
          var linkHeight = (imgElement.height() - socialShareLinks.height()) / 2;
          shareElement.css({width:imgElement.width(), height: imgElement.height()});
          shareIcons.css({width:imgElement.width(), height: imgElement.height()});
          socialShareLinks.css({'margin-top': linkHeight});
          // For quotes the text height may be smaller than share icons height
          // Increase the container height to share icons height
          if(imgElement.height() <= shareElement.height()) {
            socialShareLinks.find('a').addClass('quote_links');
            if(socialShareLinks.is(':hidden')) {
              shareIcons.addClass('showIconsInDOM');
              shareIcons.css('height', Math.max(socialShareLinks.height(), shareElement.height())+'px');
              shareIcons.removeClass('showIconsInDOM').addClass('hideIcons');
	    }
            else {
              shareIcons.css('height', Math.max(socialShareLinks.height(), shareElement.height())+'px');
            }
          } 
        });
      }
    }
  };
  editorialHub = {};
  editorialHub.mppProductFields = [
    'PRODUCT_ID',
    'DEFAULT_CAT_ID',
    'PARENT_CAT_ID',
    'PROD_RGN_NAME',
    'PROD_RGN_SUBHEADING',
    'SUB_LINE',
    'DESCRIPTION',
    'SHORT_DESC',
    'META_KEYWORDS',
    'META_DESCRIPTION',
    'PRODUCT_USAGE',
    'PROD_CAT_IMAGE_NAME',
    'PROD_CAT_DISPLAY_ORDER',
    'IMAGE_3UP',
    'IMAGE_5UP',
    'THUMBNAIL_IMAGE',
    'IMAGE_TRAY',
    'skus',
    'shaded',
    'sized',
    'worksWith',
    'url',
    'RATING_IMAGE',
    'DISPLAY_STATUS',
    'WWW_PCODE_4',
    'DRUPAL_URL',
    'priceRange',
    'AVERAGE_RATING',
    'RATING_RANGE',
    'RECOMMENDED_PERCENT',
    'TOTAL_REVIEW_COUNT',
    'ATTRIBUTE_BENEFIT',
    'SKINTYPE_DESC',
    'SKIN_CONCERN',
    'SKIN_CONCERN_1',
    'SKIN_CONCERN_2',
    'SKIN_CONCERN_3',
    'FORMULA',
    'isDisplayable',
    'FAMILY_CODE',
    'MISC_FLAG',
    'ATTRIBUTE_COLLECTION',
    'EFFICACY_MSG',
    'DISPLAY_ORDER',
    'USES_SKU_IMAGES'
  ];
})(jQuery);
